import BaseService from "./Base.Service";

class ProjectHoursService extends BaseService {
	constructor() {
		const model = {
			name: { type: String, required: true },
			users: { type: Array, required: true }
		};

		super("project-hours", model);
	}
    async delete(hourId) {
        try {
            const { data } = await this.conn.axios.delete(`/project-hours/${hourId}`);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
	async approve(body) {
        try {
            const { data } = await this.conn.axios.post(`/project-hours/approve-hours`, body);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
    async myApprovedHours(params) {
        try {
            const { data } = await this.conn.axios.get(`/project-hours/my-approved-hours?reference=${params}`);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
}

export default ProjectHoursService;
